import './App.css';
// import CountDown from './CountDown';
import CountDownTimer from './CountDownTimer';

function App() {

 
  const hoursMinSecs={hours : 10, minute : 0, secound : 0}

  return (
    <div className="App">
      <CountDownTimer hoursMinSecs={hoursMinSecs}/>
    {/* <CountDown hoursMinSecs={hoursMinSecs}/> */}
    </div>
  );
}

export default App;
