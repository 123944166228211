import React,{useState,useEffect} from 'react'
import './countdown.css'

const CountDownTimer = ({hoursMinSecs}) => {

    const[start,setStart]=useState(false)
const {hours = 0 ,minute = 0,secound = 0}= hoursMinSecs;
const [[hrs,min,sec],setTime]=useState([hours,minute,secound])



const tick=()=>{
    
    if(hrs===0 && min===0 && sec===0){
    reset()
    }else if(min===0 && sec===0 ){
        setTime ([hrs-1,59,59])
    }else if(sec===0){
        setTime([hrs,min-1,59])
    }else{
        setTime([hrs,min,sec-1])
    }

    }

const reset=()=>{
   setTime([parseInt(hours),parseInt(minute),parseInt(secound)])
}


var interVal
    useEffect(() => {
        if(start==true)
         interVal=setInterval(() => {
            tick()
        }, 1000);
        return()=>clearInterval(interVal)
       
    });

    

const StartBtn=()=>{
 setStart(start=>!start)
 reset()
    
}

const PauseBtn=()=>{
    return()=>clearInterval(interVal)
}

const ResumeBtn=()=>{
   return()=>setInterval(tick(),1000)
}

const RestartBtn=()=>{
    reset()
    setStart(start==false)
}
  return (
    <div className='countdown-cont'>
        <h1 className='houre-text'>
            {`${hrs.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${sec.toString().padStart(2,'0')}`}
        </h1>
        <div className='buttons'>
            <button className='start-btn' onClick={StartBtn}>Start</button>
            <button className='start-btn' onClick={PauseBtn()}>Pause</button>
            <button className='start-btn' onClick={ResumeBtn()}>Resume</button>
            <button className='start-btn' onClick={RestartBtn}>Reset</button>
        </div>
    </div>
  )
}

export default CountDownTimer